import logo from './logo.svg';
import './App.css';
import collection from './collection.json';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [lastElement, setLastElement] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    let loaded = 0;
    const totalImages = collection.length;

    const handleLoad = () => {
      loaded++;
      if (loaded === totalImages) {
        setImagesLoaded(true);
      }
    };

    collection.forEach((image) => {
      const img = new Image();
      img.src = `https://images.gamma.io/cdn-cgi/image/quality=100,width=1200,height=1200/https://ordinals-ws.gamma.io/content/${image.inscription}`;
      img.addEventListener('load', handleLoad);
    });
  }, [collection]);
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    const elementId = parseInt(searchQuery, 10);
    const isIdPresent = collection.some(item => item.number === elementId);
    if (!isIdPresent) {
      toast.warn('Inscription # not found. Please contact us.', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    const element = document.getElementById(`${elementId}`);
    if (element) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            observer.disconnect();
            if (lastElement) {
              lastElement.classList.remove("highlight");
            }
            element.classList.add("highlight");
          }
        });
      });
      
      observer.observe(element);

      setLastElement(element);
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  };


  const imageGrid = collection.map((image) => (
    <a key={image.id} href={`https://ordinals.com/inscription/${image.inscription}`} target="_blank" rel="noopener noreferrer">
      <div className="item" id={image.number}>
        <img key={image.id} src={`https://images.gamma.io/cdn-cgi/image/quality=100,width=1200,height=1200/https://ordinals-ws.gamma.io/content/${image.inscription}`} alt={image.id} />
        <h6 className="img-name">{image.id}</h6>
        <span class="tooltiptext">
          Buggy #{image.id}
          <br />
          Inscription #{image.number}
        </span>
      </div>
    </a>
  ));
  window.onload = function() {
    const grid = document.getElementById('grid');
    if (!grid) return;
    
    const gridItems = grid.getElementsByTagName('a');
    const shiftAmounts = [0, 50, 100, 50];
    
    for (let i = 0; i < gridItems.length; i++) {
      const row = Math.floor(i / 5);
      const shiftAmount = shiftAmounts[row % shiftAmounts.length];
      if (shiftAmount !== 0 && i % 5 < 5) {
        gridItems[i].classList.add('right' + shiftAmount);
      }
    }
  };
  
  return (
    <div className="home">
      <h1 className="title">BeepBeepBuggies</h1>
        <div className="socials">
          <a href="https://twitter.com/beepbeepbuggies" target="_blank" rel="noopener noreferrer"><img src="twitter.svg" alt="" /></a>
          <a href="https://discord.gg/6df6UXJGtU" target="_blank" rel="noopener noreferrer"><img src="discord.svg" alt="" /></a>
        </div>
      <p className="desc">
      Welcome to Buggies Explorer V.1. Here, you can verify the authenticity of your BeepBeep by searching for its inscription ID. Please note that the maximum supply of BeepBeepBuggies is 300. It's important to note that there may be some transactions that have been delayed in the blockchain due to insufficient fees. If these transactions are processed, the total supply of BeepBeep buggies will not exceed 300.      </p>
      <div className='search'>
        <input type="number" min="1" max="196" placeholder='Inscription #' value={searchQuery} onChange={handleInputChange} />
        <button onClick={handleSearch}><img src="search.svg" alt="" /></button>
      </div>
      {imagesLoaded ? <div className="grid" id="grid">{imageGrid}</div> : <div className='center'>Loading...</div>}
     
     
      
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
